code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body {
  margin: 0;
  padding: 0;
  background-color: #dddddd;
}

html,
body,
div,
span,
p {
  font-family: 'Roboto', sans-serif;
}

h1 {
  font-family: 'Solway', serif;
  margin: 10px 0 0 0;
}

footer {
  width: 100%;
  height: 50px;
}

footer p {
  text-align: right;
  padding-top: 15px;
  width: 99%;
  margin: 0;
}
